<template>
	<div :class="['tab', className('tab-')]">
		<div class="tab-tabs tab-tabs-border">
			<div :class="['tab-item', { 'tab-item-activity': key == current }]" v-for="(vo, key) in data"
				@click="onClick(key, vo)">
				{{ vo.title }}
				<span class="tab-item-dot" v-show="vo.isShowDot == true"></span>
			</div>
		</div>
		<div class="tab-more" v-if="more" @click="onClickMore">查看全部</div>
		<div class="tab-search" @click="onClickSearch" v-if="search"></div>
	</div>
</template>

<script>
export default {
	inject: ['className'],
	data() {
		return {
			current: 0
		};
	},
	created() {
		this.current = this.active;
	},
	props: {
		active: {
			default: 0
		},
		data: {
			default: []
		},
		more: {
			default: false
		},
		search: {
			default: false
		}
	},
	watch: {
		active() {
			this.current = this.active;
		}
	},
	methods: {
		onClick(key, vo) {
			if (this.current != key) {
				this.$emit('click', key, vo);
			}
		},
		onClickMore() {
			this.$emit('click-more');
		},
		onClickSearch() {
			this.$emit('click-search');
		}
	}
};
</script>

<style lang="less" scoped>
.tab {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.tab-tabs {
		display: flex;
		line-height: 30px;

		&.tab-tabs-border {
			position: relative;
			padding-left: 10px;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				width: 3px;
				height: 20px;
				transform: translateY(-50%);
				border-radius: 100px;
			}
		}

		.tab-item {
			color: #333;
			font-size: 18px;
			margin-right: 15px;
			position: relative;

			&.tab-item-activity {
				font-size: 18px;
				font-weight: bold;
			}

			.tab-item-dot {
				position: absolute;
				top: 0;
				right: -5px;
				width: 8px;
				height: 8px;
				background-color: #EB3D3C;
				border-radius: 50%;
			}
		}
	}

	.tab-more {
		font-size: 14px;
		color: #666;
		padding-right: 16px;
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 14px;
	}

	.tab-search {
		width: 30px;
		height: 30px;
		background-image: url('~@/assets/img/tab_searct.png');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: cover;
	}
}

.tab-city {
	.tab-tabs-border:after {
		background-color: #3377ff;
	}

	.tab-item.tab-item-activity {
		color: #3377ff;
	}

	.tab-more {
		font-size: 14px;
		color: #666;
		padding-right: 16px;
		background-image: url('~@/assets/img/w_title_icon_city.png');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 14px;
	}
}

.tab-district {
	.tab-tabs-border:after {
		background-color: #ff6969;
	}

	.tab-item.tab-item-activity {
		color: #ff6969;
	}

	.tab-more {
		font-size: 14px;
		color: #666;
		padding-right: 16px;
		background-image: url('~@/assets/img/w_title_icon_district.png');
		background-repeat: no-repeat;
		background-position: right center;
		background-size: 14px;
	}
}
</style>
