import Config from "@/vendor/config"
import Request from "@/api/request.js"


// 全部爱心传递
const donateList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/list', params)
}


// 首页爱心传递
const donateIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/index', params)
}

// 爱心传递详情
const donateDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/detail', params)
}

// 爱心传递 捐赠
const toDonate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/toDonate', params)
}

// 爱心传递 捐赠
const record = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/donate/record', params)
}

export default {
	donateList,
	donateIndex,
	donateDetail,
	toDonate,
	record
}
