<template>
	<div class="transfer-list">
		<vant-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="middle">
				<div class="middle-top">
					<div class="middle-top-item" v-for="(item, index) in navTop" @click="onNav(index)">
						<div :class="['middle-top-title', { 'title-color': navActive == index }]">{{ item.text }}</div>
						<div v-if="navActive == index" class="middle-top-line"><span></span></div>
					</div>
				</div>

				<div class="middle-item" v-for="item in transferList">
					<div class="middle-item-left">
						<div class="left-picture"><img :src="item.avatar" alt="" /></div>
						<div class="left-box">
							<div class="title">{{ item.nominal }}</div>
							<div class="text">捐赠时间：{{ item.create_time }}</div>
						</div>
					</div>
					<div class="middle-item-right">{{ item.integral }}</div>
				</div>
			</div>
		</vant-list>
	</div>
</template>

<script>
	import WTab from '@/components/Tab';
	import VantList from '@/components/VantList';
	import Transfer from '@/api/transfer';
	export default {
		name: 'TransferList',
		data() {
			return {
				navTop: [{
						order: 1,
						text: '积分排序'
					},
					{
						order: 2,
						text: '时间排序'
					}
				],
				transferList: [],
				loading: true,
				finished: false,
				isEmpty: false,
				navActive: 0,
				transferId: '',
				order: 1,
				page: 1,
				limit: 10
			}
		},
		created() {
			this.transferId = this.$route.params.id;
			this.onLoad();
		},
		methods: {
			onLoad() {
				this.loading = true;

				Transfer.record({
					fundraiser_id: this.transferId,
					order: this.order,
					page: this.page,
					limit: this.limit
				}).then(result => {

					this.transferList = this.transferList.concat(result.data.list);

					if (this.transferList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.transferList.length >= result.data.total) {
						this.finished = true;
					}

					this.page++;
					this.loading = false;

				}).catch(error => {
					console.log(error);
				});
			},
			progressWidth(current, total) {
				let progress = parseInt((current / total) * 100);
				return progress < 100 ? progress : 100;
			},
			onNav(index) {
				this.navActive = index;
				this.order = this.navTop[index].order;
				this.finished = false;
				this.page = 1;
				this.transferList = [];
				this.onLoad();
			}
		},
		components: {
			WTab,
			VantList
		}
	};
</script>

<style lang="less" scoped>
	.transfer-list {
		.public {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.head-top {
			width: 100%;
			height: 200px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.nav {
			background: #f2f2f2;
			border-radius: 15px 15px 0px 0px;
			padding: 13px 10px 2px 10px;
			margin-top: -13px;
			position: relative;
			z-index: 2;
		}

		.middle {
			margin: 10px;
			padding: 0 10px 10px 10px;
			box-sizing: border-box;
			background: #ffffff;
			border-radius: 6px;
			margin-bottom: 0;

			.middle-top {
				padding: 10px 0 17px;
				display: flex;
				justify-content: flex-start;
				border-bottom: 1px solid #f2f2f2;

				.middle-top-title {
					font-size: 16px;
					line-height: 30px;
					color: #333;
					margin-right: 10px;
				}

				.title-color {
					font-weight: bold;
					color: #3377ff;
				}

				.middle-top-line {
					margin-left: 15px;

					span {
						display: block;
						width: 28px;
						height: 2px;
						background: #3377ff;
						border-radius: 10px;
					}
				}
			}

			.middle-item {
				padding: 15px 0 12px;
				border-bottom: 0.5px dashed #ddd;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.middle-item-left {
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.left-picture {
						width: 48px;
						min-width: 48px;
						height: 48px;
						margin-right: 10px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.left-box {
						.title {
							font-size: 16px;
							line-height: 26px;
							color: #333;
						}

						.text {
							font-size: 12px;
							line-height: 20px;
							color: #666;
						}
					}
				}

				.middle-item-right {
					font-size: 20px;
					line-height: 26px;
					color: #3377ff;
				}
			}
		}
	}
</style>
